
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.howThisWork {
    padding: 60px 0;
    @media screen and (max-width: 1320px) {
        padding-left: 20px;
        padding-right: 20px;
        justify-content: space-between;
    }
}
.howThisWorkImage {
    width: 42%;
    svg {
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        width: 80%;
        margin: 0 auto;
        svg {
            height: auto;
        }
    }
}

.howThisWorkListWrapper {
    width: 58%;
    @media screen and (max-width: 1320px) {
        width: 48%;
    }
    @media screen and (max-width: 767px) {
        width: auto;
    }
}

.stepNum {
    width: 100%;
    max-width: 67px;
    height: 67px;
    border: 2px solid #ea7969;
    color: #ea7969;
    border-radius: 67px;
    font-size: 30px;
    margin-right: 15px;
    @media screen and (max-width: 1320px) {
        max-width: 45px;
        height: 45px;
    }
}
.stepText {
    color: #444b53;
}
